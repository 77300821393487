import styled from 'styled-components'
import {
    display,
    alignItems,
    justifyContent,
    flexWrap,
    flexDirection,
    boxShadow,
    color,
    space,
    borderRadius,
    width,
    height,
} from 'styled-system'

const NavbarStyle = styled.nav`
    /* Navbar default style goes here */
    display: flex;
    align-items: center;
    min-height: 56px;
    padding: 2px 16px;

    /* Style system supported prop */
    ${display}
    ${alignItems}
  ${justifyContent}
  ${flexDirection}
  ${flexWrap}
  ${width}
  ${height}
  ${color}
  ${space}
  ${boxShadow}
  ${borderRadius}
`

NavbarStyle.displayName = 'NavbarStyle'

export default NavbarStyle
