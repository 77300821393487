import React, { Fragment } from 'react'
import Sticky from 'react-stickynode'
import { ThemeProvider } from 'styled-components'
import { interiorTheme } from '../common/theme/interior'
import { DrawerProvider } from '../common/contexts/DrawerContext'
import Navbar from '../components/Navbar'
import Banner from '../components/Banner'
import Feature from '../components/Feature'
import AboutUs from '../components/AboutUs'
import Project from '../components/Project'
import Team from '../components/Team'
import Footer from '../components/Footer'
import { ResetCSS } from '../common/assets/css/style'
import {
    GlobalStyle,
    InteriorWrapper,
    ContentWrapper,
} from '../components/interior.style'
import Seo from '../components/seo'

const Interior = () => {
    return (
        <ThemeProvider theme={interiorTheme}>
            <Fragment>
                <Seo title='PT Synergy Network Indonesia' />
                <ResetCSS />
                <GlobalStyle />

                {/* Start writing your markup from here. */}
                <InteriorWrapper>
                    <Sticky
                        top={0}
                        innerZ={9999}
                        activeClass='sticky-nav-active'
                    >
                        <DrawerProvider>
                            <Navbar logoStyle={{padding: "2px",height: "80px"}} />
                        </DrawerProvider>
                    </Sticky>
                    <ContentWrapper>
                        <Banner />
                        <Feature />
                        <AboutUs />
                        <Project />
                        <Team />
                    </ContentWrapper>
                    <Footer />
                </InteriorWrapper>
                {/* End of markup section. */}
            </Fragment>
        </ThemeProvider>
    )
}
export default Interior
