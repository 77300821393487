import React from 'react'
import Fade from 'react-reveal/Fade'
import { useStaticQuery, graphql } from 'gatsby'
import { getSrc, getSrcSet } from 'gatsby-plugin-image'
import Text from 'common/components/Text'
import Heading from 'common/components/Heading'
import Container from 'common/components/UI/Container'
import GlideCarousel from 'common/components/GlideCarousel'
import GlideSlide from 'common/components/GlideCarousel/glideSlide'
import { SectionHeader } from '../interior.style'
import SectionWrapper, {
    TeamCard,
    ImageWrapper,
    TextWrapper,
    CarouselWrapper,
} from './team.style'

const Team = () => {
    const data = useStaticQuery(graphql`
        query {
            dataYaml {
                teamData {
                    title
                    slogan
                    members {
                        avatar {
                            childImageSharp {
                                gatsbyImageData(
                                    layout: FULL_WIDTH
                                    placeholder: BLURRED
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                        id
                        name
                        designation
                    }
                }
            }
        }
    `)
    const { title, slogan, members } = data.dataYaml.teamData

    const glideOptions = {
        type: 'carousel',
        perView: 3,
        gap: 50,
        breakpoints: {
            1200: {
                perView: 3,
                gap: 30,
            },
            767: {
                perView: 2,
                gap: 30,
            },
            480: {
                perView: 1,
            },
        },
    }

    return (
        <SectionWrapper id='team'>
            <Container width='1360px'>
                <Fade bottom>
                    <SectionHeader>
                        <Heading as='h5' content={title} />
                        <Heading content={slogan} />
                    </SectionHeader>
                </Fade>
                <Fade bottom delay={30}>
                    <CarouselWrapper>
                        <GlideCarousel
                            carouselSelector='team_carousel'
                            options={glideOptions}
                            nextButton={<span className='next_arrow' />}
                            prevButton={<span className='prev_arrow' />}
                        >
                            {members.map(member => (
                                <GlideSlide key={`team_key${member.id}`}>
                                    <TeamCard className='team_card'>
                                        <ImageWrapper className='image_wrapper'>
                                            <img
                                                src={getSrc(member.avatar)}
                                                srcSet={getSrcSet(
                                                    member.avatar
                                                )}
                                            />
                                        </ImageWrapper>
                                        <TextWrapper className='text_wrapper'>
                                            <div className='name_plate'>
                                                <Heading
                                                    as='h3'
                                                    content={member.name}
                                                />
                                                <Text
                                                    content={member.designation}
                                                />
                                            </div>
                                        </TextWrapper>
                                    </TeamCard>
                                </GlideSlide>
                            ))}
                        </GlideCarousel>
                    </CarouselWrapper>
                </Fade>
            </Container>
        </SectionWrapper>
    )
}

export default Team
