import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import { Icon } from 'react-icons-kit'
import { iosEmailOutline } from 'react-icons-kit/ionicons/iosEmailOutline'
import Heading from 'common/components/Heading'
import Text from 'common/components/Text'
import Button from 'common/components/Button'
import Input from 'common/components/Input'
import GlideCarousel from 'common/components/GlideCarousel'
import { getSrcSet, getSrc } from 'gatsby-plugin-image'
import GlideSlide from 'common/components/GlideCarousel/glideSlide'
import BannerWrapper, {
    Container,
    ContentArea,
    FormWrapper,
    ButtonGroup,
    CarouselArea,
} from './banner.style'
import styled from 'styled-components'
import { bannerEmailSubmit } from '../../encelerate'

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ //eslint-disable-line

const FormFeedback = styled.p`
    transition: all 0.2s ease-in-out;
    max-height: 0;
    margin: 6px 36px 0;
    overflow: hidden;
    &.invalid {
        max-height: 26px;
        color: #f88;
    }
    &.valid {
        max-height: 26px;
        color: #8f8;
    }
`
const emailFeedbackInvalid = 'Invalid Email Address'
const emailFeedbackSuccess = 'Email Submitted'
const emailFeedbackFailed = 'An error occurred, please try again'
const emailFeedbackSubmitting = 'Submitting...'

const EmailForm = () => {
    const [txt, setTxt] = useState('')
    const [inputClassName, setInputClassName] = useState('')
    const [feedback, setFeedback] = useState('')
    const onChange = value => {
        setTxt(value)
        if (value.length < 3) {
            setFeedback('')
            return setInputClassName('')
        }
        if (value.match(emailRegex) && inputClassName.length > 0) {
            setFeedback('')
            setInputClassName('valid')
        }
    }
    const onBlur = () => {
        if (txt.match(emailRegex)) {
            setInputClassName('valid')
        } else {
            setInputClassName('invalid')
            setFeedback(emailFeedbackInvalid)
        }
    }

    const handleSubscriptionForm = e => {
        e.preventDefault()
        if (feedback.length) return
        if (!txt.match(emailRegex)) {
            setInputClassName('invalid')
            setFeedback(emailFeedbackInvalid)
            return
        }
        setInputClassName('valid')
        setFeedback(emailFeedbackSubmitting)
        bannerEmailSubmit(txt)
            .then(() => {
                setFeedback(emailFeedbackSuccess)
            })
            .catch(() => {
                setInputClassName('invalid')
                setFeedback(emailFeedbackFailed)
            })
    }
    return (
        <FormWrapper onSubmit={handleSubscriptionForm}>
            <Input
                className={`${inputClassName} input_element`}
                type='email'
                placeholder='Enter email address'
                icon={<Icon icon={iosEmailOutline} />}
                iconPosition='left'
                required={true}
                onChange={onChange}
                onBlur={onBlur}
                aria-label='email'
                value={txt}
                disabled={feedback === emailFeedbackSuccess}
            />
            <FormFeedback className={inputClassName}>{feedback}</FormFeedback>
            <ButtonGroup>
                <Button
                    type='submit'
                    colors='primaryWithBg'
                    title='FREE CONSULTATION'
                    disabled={feedback === emailFeedbackSuccess}
                />
                <ExploreMoreButton />
            </ButtonGroup>
        </FormWrapper>
    )
}

const ExploreMoreButton = () => (
    <Button
        title='EXPLORE MORE'
        variant='textButton'
        icon={<i className='flaticon-next' />}
        onClick={() => {
            if (window) {
                window.scroll({
                    top: window.innerHeight,
                    behavior: 'smooth',
                })
            }
        }}
    />
)

const Banner = () => {
    const data = useStaticQuery(graphql`
        query {
            dataYaml {
                bannerData {
                    title
                    text
                    carousel {
                        id
                        title
                        thumb_url {
                            childImageSharp {
                                gatsbyImageData(
                                    layout: FULL_WIDTH
                                    placeholder: NONE
                                    formats: [AUTO, WEBP, AVIF]
                                )
                            }
                        }
                    }
                }
            }
        }
    `)

    const { title, text, carousel } = data.dataYaml.bannerData

    const glideOptions = {
        type: 'carousel',
        perView: 3,
        gap: 20,
        breakpoints: {
            1200: {
                perView: 2,
            },
            667: {
                perView: 2,
            },
            480: {
                perView: 1,
            },
        },
    }

    return (
        <BannerWrapper id='home'>
            <Container>
                <ContentArea>
                    <Fade bottom delay={30}>
                        <Heading as='h1' content={title} />
                        <Text content={text} />
                        <EmailForm />
                    </Fade>
                </ContentArea>
                {/* End of content section */}

                <CarouselArea>
                    <GlideCarousel
                        carouselSelector='interior_carousel'
                        options={glideOptions}
                        nextButton={<span className='next_arrow' />}
                        prevButton={<span className='prev_arrow' />}
                    >
                        {carousel.map(item => (
                            <GlideSlide key={`carousel_key${item.id}`}>
                                <div className='item_wrapper'>
                                    <img
                                        src={getSrc(
                                            item.thumb_url.childImageSharp
                                                .gatsbyImageData
                                        )}
                                        srcSet={getSrcSet(
                                            item.thumb_url.childImageSharp
                                                .gatsbyImageData
                                        )}
                                        alt={item.title}
                                        className='carousel_img'
                                    />
                                    <Heading as='h4' content={item.title} />
                                </div>
                            </GlideSlide>
                        ))}
                    </GlideCarousel>
                </CarouselArea>
                {/* End of carousel section */}
            </Container>
        </BannerWrapper>
    )
}

export default Banner
