import React from 'react'
import Link from 'common/components/Link'

export const GetCopyright = () => (
    <>
        <p className='text'>
            &copy;{new Date().getFullYear()} PT Synergy Network Indonesia
        </p>
        <p>
            Built & designed with
            <svg
                xmlns='http://www.w3.org/2000/svg'
                width='11.243'
                height='10.378'
                viewBox='0 0 11.243 10.378'
            >
                <path
                    id='f141'
                    d='M10.324-44.135a3.191,3.191,0,0,1,.919,2.311,3.191,3.191,0,0,1-.919,2.311l-4.7,4.892-4.7-4.892A3.191,3.191,0,0,1,0-41.825a3.191,3.191,0,0,1,.919-2.311A2.618,2.618,0,0,1,2.905-45a2.618,2.618,0,0,1,1.986.865l.73.757.757-.757A2.6,2.6,0,0,1,8.351-45,2.6,2.6,0,0,1,10.324-44.135Zm0,0'
                    transform='translate(0 45)'
                    fill='#f4291e'
                />
            </svg>{' '}
            by <Link href='https://www.intalko.id'>Intalko</Link>
        </p>
    </>
)
export const scrollToTop = () => {
    if (window) {
        window.scroll({ behavior: 'smooth', top: 0 })
    }
}

const gFormUrl =
    'https://docs.google.com/forms/d/e/1FAIpQLScSpkjW-sAC5UXyqvxdZLQJjWaID2QjPku09-Af3DAkwZu0wA/formResponse'

export const bannerEmailSubmit = email => {
    const requestUrl = new URL(gFormUrl)
    requestUrl.searchParams.set('entry.225266782', email)
    return fetch(requestUrl.toString(), {
        mode: 'no-cors',
    })
}
