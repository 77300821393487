import React, { Fragment } from 'react'
import Fade from 'react-reveal/Fade'
import { useStaticQuery, graphql } from 'gatsby'
import Text from 'common/components/Text'
import Heading from 'common/components/Heading'
import Image from 'common/components/Image'
import Container from 'common/components/UI/Container'
import GlideCarousel from 'common/components/GlideCarousel'
import GlideSlide from 'common/components/GlideCarousel/glideSlide'
import { SectionHeader } from '../interior.style'
import SectionWrapper, {
    ProjectWrapper,
    TextWrapper,
    ImageWrapper,
} from './project.style'

const Project = () => {
    const data = useStaticQuery(graphql`
        query {
            dataYaml {
                projectData {
                    title
                    slogan
                    thumb_url {
                        publicURL
                    }
                    projects {
                        id
                        title
                        text
                    }
                }
            }
        }
    `)

    const { title, slogan, thumb_url, projects } = data.dataYaml.projectData

    const glideOptions = {
        type: 'carousel',
        perView: 1,
        gap: 0,
    }

    return (
        <SectionWrapper id='project'>
            <Container width='1360px'>
                <Fade bottom>
                    <SectionHeader>
                        <Heading as='h5' content={title} />
                        <Heading content={slogan} />
                    </SectionHeader>
                </Fade>

                <ProjectWrapper>
                    <GlideCarousel
                        carouselSelector='project_carousel'
                        options={glideOptions}
                        nextButton={<span className='next_arrow' />}
                        prevButton={<span className='prev_arrow' />}
                    >
                        <Fragment>
                            {projects.map(project => (
                                <GlideSlide key={`project_key${project.id}`}>
                                    <TextWrapper>
                                        <Text fontWeight="bold" content={project.title} />
                                        <Text content={project.text} />
                                    </TextWrapper>
                                </GlideSlide>
                            ))}
                        </Fragment>
                    </GlideCarousel>

                    <ImageWrapper>
                        <Image
                            src={thumb_url.publicURL}
                            alt='Interiro landing by RedQ'
                        />
                    </ImageWrapper>
                </ProjectWrapper>
            </Container>
        </SectionWrapper>
    )
}

export default Project
