import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Icon } from 'react-icons-kit'
import { androidClose } from 'react-icons-kit/ionicons/androidClose'
import NavbarWrapper from 'common/components/Navbar'
import Drawer from 'common/components/Drawer'
import Logo from 'common/components/UIElements/Logo'
import HamburgMenu from 'common/components/HamburgMenu'
import ScrollSpyMenu from 'common/components/ScrollSpyMenu'
import { DrawerContext } from 'common/contexts/DrawerContext'
import { Container } from './navbar.style'

import logo from '../../assets/logoSynergyWeb.png'
import { scrollToTop } from '../../encelerate'

const Navbar = ({ navbarStyle, logoStyle }) => {
    const data = useStaticQuery(graphql`
        query {
            dataYaml {
                menuData {
                    path
                    label
                    offset
                }
                socialProfile {
                    id
                    icon
                    link
                }
            }
        }
    `)

    const { state, dispatch } = useContext(DrawerContext)

    const toggleHandler = () => {
        dispatch({
            type: 'TOGGLE',
        })
    }

    return (
        <NavbarWrapper {...navbarStyle}>
            <Container>
                <Logo
                    onClick={scrollToTop}
                    logoSrc={logo}
                    title='Home'
                    logoStyle={logoStyle}
                    withAnchor
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Drawer
                        width='420px'
                        placement='right'
                        drawerHandler={<HamburgMenu />}
                        open={state.isOpen}
                        toggleHandler={toggleHandler}
                    >
                        <button
                            type='button'
                            className={state.isOpen ? 'active' : ''}
                            onClick={toggleHandler}
                            aria-label='drawer toggle button'
                        >
                            <Icon icon={androidClose} />
                        </button>
                        <ScrollSpyMenu
                            menuItems={data.dataYaml.menuData}
                            drawerClose={true}
                            offset={-100}
                        />
                    </Drawer>
                </div>
            </Container>
        </NavbarWrapper>
    )
}

Navbar.propTypes = {
    navbarStyle: PropTypes.object,
    logoStyle: PropTypes.object,
}

Navbar.defaultProps = {
    navbarStyle: {
        minHeight: '70px',
    },
    logoStyle: {
        width: '128px',
        height: 'auto',
    },
}

export default Navbar
