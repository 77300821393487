import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Box from 'common/components/Box'
import Text from 'common/components/Text'
import Logo from 'common/components/UIElements/Logo'
import Container from 'common/components/UI/Container'
import FooterWrapper, { CurvIcon } from './footer.style'
import styled from 'styled-components'
import { GetCopyright, scrollToTop } from '../../encelerate'

const footerBreakpoint = '600px'
const StyledFooter = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    > div {
        width: 100%;
        margin-bottom: 24px;
    }
    .logo img {
        height: 80px;
        width: auto;
        max-width: unset;
    }
    @media only screen and (min-width: ${footerBreakpoint}) {
        .logo {
            width: 40%;
        }
        .map {
            width: 60%;
        }
    }
`

const StyledIframe = styled.iframe`
    width: 100%;
    height: 300px;
    border: 0;
    @media only screen and (max-width: 900px) {
        height: 400px;
    }
`

const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            dataYaml {
                footerData {
                    logo {
                        publicURL
                    }
                    contactInfo
                }
            }
        }
    `)
    const { logo, contactInfo } = data.dataYaml.footerData
    return (
        <Fragment>
            <CurvIcon>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 1920 222.29'
                >
                    <g>
                        <g>
                            <path d='M0,222.29S547.82,11.93,1248.06.64C1867.75-9.36,1920,101.3,1920,101.3v121Z' />
                        </g>
                    </g>
                </svg>
            </CurvIcon>

            <FooterWrapper id='footer'>
                <Container width='1330px'>
                    <StyledFooter>
                        <div className='logo'>
                            <Logo
                                onClick={scrollToTop}
                                logoSrc={logo.publicURL}
                                title='PT Synergy Network Indonesia'
                                withAnchor
                            />
                            {contactInfo.map(r => (
                                <Text className='text' content={r} key={r} />
                            ))}
                        </div>
                        <div className='map'>
                            <StyledIframe
                                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.683313563368!2d106.72802991476891!3d-6.173137695530778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f752291e592d%3A0x7b31e191f02bc7c9!2sPT.%20Synergy%20Network%20Indonesia!5e0!3m2!1sen!2sid!4v1639472811610!5m2!1sen!2sid'
                                loading='lazy'
                            />
                        </div>
                    </StyledFooter>
                    <Box className='copyright'>
                        <div style={{ textAlign: 'center' }}>
                            <GetCopyright />
                        </div>
                    </Box>
                </Container>
            </FooterWrapper>
        </Fragment>
    )
}

// Footer style props
Footer.propTypes = {
    row: PropTypes.object,
    col: PropTypes.object,
    colOne: PropTypes.object,
    colTwo: PropTypes.object,
}

// Footer default style
Footer.defaultProps = {
    // Footer row default style
    row: {
        flexBox: true,
        flexWrap: 'wrap',
        ml: '-15px',
        mr: '-15px',
    },
    // Footer col one style
    colOne: {
        width: ['100%', '30%', '35%', '28%'],
        mt: [0, '13px', '0'],
        mb: ['30px', 0],
        pl: ['15px', 0],
        pr: ['15px', '15px', 0],
    },
    // Footer col two style
    colTwo: {
        width: ['100%', '70%', '65%', '72%'],
        flexBox: true,
        flexWrap: 'wrap',
    },
    // Footer col default style
    col: {
        width: ['100%', '50%', '50%', '25%'],
        pl: '15px',
        pr: '15px',
        mb: '30px',
    },
}

export default Footer
