const colors = {
    transparent: 'transparent',
    label: '#C6C6C6',
    lightBorder: '#f1f4f6',
    inactiveField: '#f2f2f2',
    inactiveButton: '#b7dbdd',
    inactiveIcon: '#EBEBEB',
    primaryHover: '#EDE10F',
    secondary: '#D50032',
    secondaryHover: '#EE2541',
    yellow: '#fdb32a',
    yellowHover: '#F29E02',
    border: '#dadada',
    black: '#000000',
    white: '#ffffff',
    primary: '#9B9B9B',
    heading: '#191919',
    heading3: '#273343',
    quote: '#343d48',
    text: '#4E5865',
    lightText: '#7E7E7E',
    link: '#352FD9',
    banner: '#171717',
    darkBg: '#0D0D0D',
    lightBg: '#fbfafe',
}

export default colors
